h1 {
    font-size: 75%;
    font-weight: normal;
    color: #64ffda;
}

h1 b{
    font-size: 200%;
    color: #e6f1ff;
}

p {
    color: #a8b2d1;
}