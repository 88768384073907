  #logo {
    height: 40vmin;
    filter: invert(100%);
  }
  
img.rotate {
  animation: app-logo-spin infinite 1.5s linear;
}

  @keyframes app-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  